import type { CombinedQueriesData } from '@autobid/strapi-integration/graphql/helpers/getDynamicCombinedQuery'
import getDynamicCombinedQuery from '@autobid/strapi-integration/graphql/helpers/getDynamicCombinedQuery'
import type { AutobidCmsConfig } from '@autobid/strapi-integration/typescript/Config'
import GET_NAVIGATION from '@autobid/strapi-integration/graphql/queries/getNavigation.gql'
import GET_LAYOUT from '@autobid/strapi-integration/graphql/queries/getLayout.gql'

export default (lang: string, cmsConfig: AutobidCmsConfig) => {
  const { layout, navName, singularCollection } = cmsConfig
  const queries: CombinedQueriesData[] = []
  const cacheSlug = layout || navName
  const cacheKey = cacheSlug
    ? `${singularCollection}:${lang}:layout.${cacheSlug}`
    : ''

  if (layout?.length) {
    queries.push({
      query: GET_LAYOUT,
      variables: {
        paramLayoutSlug: layout,
        paramLayoutLocale: lang
      }
    })
  }

  if (navName?.length) {
    queries.push({
      query: GET_NAVIGATION,
      variables: {
        paramNavName: navName,
        paramNavLang: lang
      }
    })
  }

  return {
    ...getDynamicCombinedQuery(queries),
    cacheKey
  }
}
