// graphql-combine-query - I need to change working of function a little bit 

import { DocumentNode, OperationDefinitionNode, DefinitionNode, Kind } from 'graphql'

type OperationVariables = Record<string, any>

export interface NewCombinedQueryBuilder {
  operationName: string,
  add: <TData = any, TVariables extends OperationVariables = OperationVariables>(document: DocumentNode, variables?: TVariables) => CombinedQueryBuilder<TData, TVariables>
}

export interface CombinedQueryBuilder<TData = any, TVariables extends OperationVariables = {}> {
  document: DocumentNode,
  variables?: TVariables,
  add: <TDataAdd = any, TVariablesAdd = OperationVariables>(document: DocumentNode, variables?: TVariablesAdd) => CombinedQueryBuilder<TData & TDataAdd, TVariables & TVariablesAdd>
}

class CombinedQueryError extends Error {}

class CombinedQueryBuilderImpl<TData = any, TVariables extends OperationVariables = OperationVariables> implements CombinedQueryBuilder<TData, TVariables> {

  document: DocumentNode
  variables?: TVariables

  constructor(private operationName: string, document: DocumentNode, variables?: TVariables) {
    this.document = document
    this.variables = variables
  }

  add<TDataAdd = any, TVariablesAdd = OperationVariables>(document: DocumentNode, variables?: TVariablesAdd): CombinedQueryBuilder<TData & TDataAdd, TVariables & TVariablesAdd> {

    const opDefs = this.document.definitions.concat(document.definitions).filter((def: DefinitionNode): def is OperationDefinitionNode => def.kind === 'OperationDefinition')
    if (!opDefs.length) {
      throw new CombinedQueryError('Expected at least one OperationDefinition, but found none.')
    }

    const newVars: TVariables & TVariablesAdd = (() => {
      if (this.variables && variables) {
        return {
          ...this.variables,
          ...variables
        } as TVariables & TVariablesAdd
      }
      return (variables || this.variables) as TVariables & TVariablesAdd
    })()

    let definitions: DefinitionNode[] = [{
      kind: Kind.OPERATION_DEFINITION,
      directives: opDefs.flatMap(def => def.directives || []),
      name: { kind: Kind.NAME, value: this.operationName },
      operation: opDefs[0].operation,
      selectionSet: {
        kind: Kind.SELECTION_SET,
        selections: opDefs.flatMap(def => def.selectionSet.selections)
      },
      variableDefinitions: opDefs.flatMap(def => def.variableDefinitions || [])
    }]
    const encounteredFragmentList = new Set<string>()
    const combinedDocumentDefinitions = this.document.definitions.concat(document.definitions)
    for (const definition of combinedDocumentDefinitions) {
      if (definition.kind === 'OperationDefinition') {
        continue
      }

      if (definition.kind === 'FragmentDefinition') {
        if (encounteredFragmentList.has(definition.name.value)) {
          continue
        }
        encounteredFragmentList.add(definition.name.value)
      }

      definitions = [definition, ...definitions]
    }

    const newDoc: DocumentNode = {
      kind: Kind.DOCUMENT,
      definitions
    }

    return new CombinedQueryBuilderImpl<TData & TDataAdd, TVariables & TVariablesAdd>(this.operationName, newDoc, newVars)
  }
}

export const combinedQuery = (operationName: string): NewCombinedQueryBuilder => {
  return {
    operationName,
    add<TData = any, TVariables extends OperationVariables={}>(document: DocumentNode, variables?: TVariables ) {
      return new CombinedQueryBuilderImpl<TData, TVariables>(this.operationName, document, variables)
    }
  }
}