<template>
  <autobid-layout :key="strapiLang" @error="handleError">
    <template #default="{ layoutData }">
      <Html :lang="strapiLang" />

      <template v-if="layoutData">
        <SeoTags
          v-if="layoutData.layout?.seo"
          :seo-data="layoutData.layout.seo"
        />

        <LayoutHeader :data="layoutData" />
      </template>

      <slot />

      <LayoutFooter
        v-if="layoutData?.layout?.footer"
        :footer="layoutData.layout.footer"
      />

      <div id="global-teleport-target"></div>
    </template>
  </autobid-layout>
</template>

<script lang="ts" setup>
import type { StrapiPageError } from '@autobid/strapi-integration/typescript/strapi/Page'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'

const { strapiLang } = useStrapiLang()

const handleError = (error: StrapiPageError) => {
  showError({
    statusCode: error.code,
    statusMessage: error.message,
    data: {
      hideLayout: true
    }
  })
}
</script>
