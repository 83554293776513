import type { DocumentNode } from 'graphql'
import { print } from 'graphql'
import type { CombinedQueryBuilder } from '@autobid/strapi-integration/lib/graphql-combine-query'
import { combinedQuery } from '@autobid/strapi-integration/lib/graphql-combine-query'

export interface CombinedQueriesData {
  query: DocumentNode
  variables: Record<string, string>
}

// workaround to use the same variable names in qpl files
export default (queries: CombinedQueriesData[]) => {
  let result = combinedQuery('Query') as unknown as CombinedQueryBuilder
  const variables: Record<string, string> = {}

  queries.forEach((queryData) => {
    let query = JSON.stringify(queryData.query)

    for (const variable in queryData.variables) {
      query = query.split(variable).join(variable)
      variables[variable] = queryData.variables[variable]
    }

    result = result.add(JSON.parse(query))
  })

  return { query: print(result.document), variables }
}
