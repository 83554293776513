<template>
  <li
    v-if="link.visible"
    ref="liNode"
    data-testid="nav-link-wrapper"
    class="relative z-10 w-full hover:z-20"
    :class="{
      'lg:w-auto': level === 1,
      'with-children': hasChildren
    }"
    @contextmenu="handleContext"
  >
    <div
      class="relative z-10 flex items-center justify-between after:absolute after:inset-y-1 after:left-3 after:right-2 after:rounded-md after:duration-300 lg:after:hidden"
      :class="{
        'lg:pr-3': hasChildren && level > 1,
        'after:bg-card-secondary': submenuActive
      }"
    >
      <common-link
        :key="link.id"
        :href="fullLink"
        :rel="
          link.type === 'EXTERNAL' ? 'nofollow noopener noreferrer' : undefined
        "
        data-testid="nav-link"
        class="relative z-20 flex w-full justify-between py-2 pl-5 lg:px-3"
        :class="{
          'main-link lg:py-4': level === 1,
          'text-primaryDark': level > 1
        }"
        :title="link.title"
        @click="closeMobileMenu"
      >
        {{ link.title }}
      </common-link>
      <button
        v-if="hasChildren"
        class="nav-arrow relative z-20 cursor-pointer p-3 pr-5 duration-300 lg:cursor-default lg:p-0"
        :class="{
          'lg:hidden': level === 1,
          'lg:scale-y-none -scale-y-100': submenuActive
        }"
        data-testid="submenu-toggler"
        :aria-label="$t('header.toggle-submenu')"
        @click="submenuActive = !submenuActive"
      >
        <!-- arrow -->
        <span
          class="relative -top-1 block h-3 w-3 rotate-[135deg] border-r-2 border-t-2 border-primaryDark lg:top-0 lg:rotate-45"
        ></span>
      </button>
    </div>

    <div
      v-if="hasChildren"
      ref="submenuNode"
      class="submenu block w-full pl-4 lg:invisible lg:absolute lg:block lg:w-max lg:min-w-[200px] lg:max-w-[300px] lg:pl-0 lg:opacity-0 lg:duration-300"
      :class="{
        'left-0 z-20 w-full lg:absolute lg:top-full lg:-mt-4': level === 1,
        'lg:top-0': level > 1,
        'left-full lg:pl-2': level > 1 && direction === 'right',
        'right-full lg:pr-2': level > 1 && direction === 'left'
      }"
      :style="offsetY < 0 ? `margin-top:${offsetY - 10}px` : undefined"
      data-testid="submenu"
    >
      <component
        :is="isMobileVersion ? Vue3SlideUpDown : 'div'"
        v-model="submenuActive"
        v-bind="isMobileVersion ? { duration: 250 } : { modelvalue: undefined }"
      >
        <ul class="block w-full bg-white lg:rounded-lg lg:shadow-around">
          <LayoutUiNavigationLink
            v-for="sublink in link._children"
            :key="sublink.id"
            :link="sublink"
            :level="level + 1"
            :parent-direction="direction"
          />
        </ul>
      </component>
    </div>
  </li>
</template>

<script lang="ts" setup>
import type { StrapiNavigationLink } from '@autobid/strapi-integration/typescript/strapi/Navigation'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { storeToRefs } from 'pinia'
import { useHeaderStore } from '@autobid/nuxt-pinia-store/store/useHeaderStore'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'
import { useFullLink } from '@autobid/ui/composables/useFullLink'
import { getBrowserName } from '@autobid/ui/utils/getBrowserName'
import LayoutUiNavigationLink from './UiNavigationLink.vue'

interface Props {
  link: StrapiNavigationLink
  level: number
  parentDirection?: 'left' | 'right'
}

const props = defineProps<Props>()

const submenuActive = ref(false)
const liNode = ref<HTMLElement>()
const submenuNode = ref<HTMLElement>()
const { resolution } = storeToRefs(useBodyStore())
const { isMobileMenuActive } = storeToRefs(useHeaderStore())
const browserName = getBrowserName()
const isMobileVersion = computed(() => resolution.value.width < 1024)

const fullLink = useFullLink(props.link)

const hasChildren = computed(() => Object.keys(props.link._children).length > 0)

const optionRects = computed(() => {
  if (isMobileVersion.value || !hasChildren.value) return

  const { top, left } = liNode.value?.getClientRects()[0] ?? {}

  return { top, left }
})

const offsetY = computed(
  () =>
    resolution.value.height -
    submenuNode.value?.clientHeight -
    optionRects.value?.top -
    15
)

const direction = computed(() => {
  if (props.parentDirection) return props.parentDirection

  if (!optionRects.value || props.level !== 1 || !submenuNode.value) return

  // space on the left + submenu width + another levels' widths
  return resolution.value.width <
    optionRects.value.left + submenuNode.value.clientWidth + 2 * 300
    ? 'left'
    : 'right'
})

const closeMobileMenu = () => {
  if (!fullLink.value?.length) {
    submenuActive.value = !submenuActive.value
  } else if (isMobileVersion.value) {
    isMobileMenuActive.value = false
  }
}

const handleContext = (e) => {
  if (browserName === 'firefox' && hasChildren.value) {
    e.preventDefault()
  }
}
</script>
