export const getBrowserName = () => {
  if (!process.client) return 'unknown'

  const browsers = {
    chrome: ['chrome'],
    firefox: ['firefox'],
    safari: ['safari'],
    edge: ['edge'],
    ie: ['msie', 'trident']
  }
  const userAgent = navigator.userAgent.toLowerCase()

  for (const browser in browsers) {
    const keyWords = browsers[browser]
    const match = keyWords.find((keyWord) => userAgent.includes(keyWord))

    if (match) return match as keyof typeof browsers
  }

  return 'unknown'
}
