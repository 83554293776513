import type { StrapiNavigationLink } from '@autobid/strapi-integration/typescript/strapi/Navigation'

export const useFullLink = (link: StrapiNavigationLink) => {
  return computed(() => {
    if (link.type === 'EXTERNAL') return link.externalPath as string

    if (!link.related) return undefined

    const linkData = link.related.attributes

    return `/${linkData.locale}/${linkData.slug !== '/' ? linkData.slug : ''}`
  })
}
