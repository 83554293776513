<template>
  <common-link
    v-if="logo?.length"
    :href="logo[0].url ?? `/${locale}`"
    class="nav-logo relative max-w-[12rem] px-5"
    :class="{
      scrolled: scrollY > 0
    }"
  >
    <CommonStrapiImage
      v-if="isNavbarTransparentVisible && logo[0]?.imageWhite?.data?.attributes"
      :data="logo[0].imageWhite.data.attributes"
      class="h-20 max-h-[48px] w-full object-contain lg:max-h-[64px] 2xl:max-h-[80px]"
    />
    <CommonStrapiImage
      v-else-if="logo[0]?.image?.data?.attributes"
      :data="logo[0].image.data.attributes"
      class="h-20 max-h-[48px] w-full object-contain lg:max-h-[64px] 2xl:max-h-[80px]"
    />
  </common-link>
</template>

<script lang="ts" setup>
import type { LayoutHeader } from '@autobid/strapi-integration/typescript/strapi/Layout'
import { storeToRefs } from 'pinia'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'

interface Props {
  logo?: LayoutHeader['brand']
}

defineProps<Props>()

const { locale } = useI18n()
const { scrollY } = storeToRefs(useBodyStore())
const isNavbarTransparentVisible: boolean = inject('isNavbarTransparentVisible')
</script>

<style lang="scss" scoped>
.nav-logo {
  transition: 0.3s top;
}

@media (min-width: 1024px) {
  .nav-logo.scrolled {
    top: 0;
  }
}

@media (min-width: 1680px) {
  .nav-logo {
    top: 32px;
    margin-left: 2.5rem;
  }
}
</style>
