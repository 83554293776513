<template>
  <div
    class="footer-wrapper bg-[var(--footer-background-color)] text-[var(--footer-text-color)]"
  >
    <footer
      class="footer container mx-auto border-t border-[var(--footer-border-color)] px-3 py-10"
    >
      <div class="flex w-full flex-col gap-6">
        <div
          v-if="footer.logos.length || footer.columns.length"
          class="flex w-full flex-wrap gap-6"
        >
          <!-- logos -->
          <div
            v-if="footer.logos.length"
            class="mt-9 flex w-full flex-wrap items-start gap-3 border-t py-2 pt-3 md:w-80 lg:w-96"
            data-testid="footer-logos"
          >
            <common-link
              v-for="logo in footer.logos"
              :key="logo.id"
              v-bind="getLinkAttributes(logo.url, logo.title)"
              data-testid="footer-logo"
            >
              <CommonStrapiImage
                :data="logo.image.data.attributes"
                class="h-20 w-auto"
                :alt="logo.title"
                loading="lazy"
              />
            </common-link>
          </div>

          <!-- links -->
          <div v-if="footer.columns.length" class="flex-1">
            <div class="columns-title pb-2 text-lg" data-testid="columns-title">
              {{ footer.columnsTitle }}
            </div>
            <div
              class="columns-wrapper flex flex-col flex-wrap gap-6 border-t py-2 sm:flex-row"
            >
              <div
                v-for="column in footer.columns"
                :key="column.id"
                class="flex flex-col"
              >
                <span
                  class="footer-title mb-2 font-bold uppercase tracking-wider"
                  data-testid="column-title"
                >
                  {{ column.title }}
                </span>

                <common-link
                  v-for="link in column.links"
                  :key="link.id"
                  :="{
                    href: link.url === OT_SETTINGS_LINK ? '#' : link.url,
                    id: link.url === OT_SETTINGS_LINK ? 'ot-sdk' : link.id
                  }"
                  class="mb-1 pb-1 text-sm hover:text-[var(--footer-text-hover-color)]"
                  :class="
                    link.url === OT_SETTINGS_LINK ? 'ot-sdk-show-settings' : ''
                  "
                  data-testid="footer-column-link"
                >
                  {{ link.text }}
                </common-link>
              </div>
            </div>
          </div>
        </div>

        <!-- images -->
        <div v-if="footer.imagesRows.length" class="border-t">
          <div
            v-for="row in footer.imagesRows"
            :key="row.id"
            class="flex w-full flex-wrap justify-center gap-3 gap-y-5 pt-4 md:justify-start md:gap-x-8 lg:gap-x-11"
            data-testid="footer-images-row"
          >
            <common-link
              v-for="image in row.logos"
              :key="image.id"
              data-testid="footer-img"
              v-bind="getLinkAttributes(image.url, image.title)"
            >
              <CommonStrapiImage
                :data="image.image.data.attributes"
                class="h-20 w-auto opacity-50 grayscale transition-opacity duration-300 hover:opacity-100 hover:grayscale-0"
                :alt="image.title"
                loading="lazy"
              />
            </common-link>
          </div>
        </div>

        <div
          v-if="footer.copyright && footer.links.length"
          class="flex w-full flex-wrap-reverse gap-6 border-t py-3 text-xs lg:flex-nowrap"
        >
          <!-- copyright -->
          <div class="copyright" data-testid="footer-copyright">
            {{ footer.copyright }}
          </div>

          <!-- links -->
          <div
            class="flex flex-1 flex-wrap justify-center gap-4 gap-y-2 md:justify-end"
          >
            <div v-for="link in footer.links" :key="link.id">
              <common-link
                class="hover:text-[var(--footer-text-hover-color)]"
                data-testid="footer-link"
                :href="link.url === OT_SETTINGS_LINK ? '#' : link.url"
                @click="handleClickLink(link.url)"
              >
                {{ link.text }}
              </common-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import type { LayoutFooter } from '@autobid/strapi-integration/typescript/strapi/Layout'
import { openOneTrustModal } from '@autobid/ui/utils/openOneTrustModal'

interface Props {
  footer: LayoutFooter
}

defineProps<Props>()

const OT_SETTINGS_LINK = '#uc-central-modal-show'

const getLinkAttributes = (href: string, title?: string) => {
  if (!href?.length) return {}

  return {
    href,
    title,
    rel: 'nofollow noopener'
  }
}

const handleClickLink = (url: string) => {
  if (url === OT_SETTINGS_LINK) {
    openOneTrustModal()
  }
}
</script>
